import { adminRoles } from './mock';

export const variablesTypes = {
  LIST: 'list',
  STRING: 'string',
  DATE: 'date',
  CHECKBOX: 'checkbox'
};

export const strictattrsSchema = {
  id: {
    charset: 'D',
    required: true,
    inputWidth: 80
  },
  name: {
    charset: 'REDS',
    required: true
  },
  type: {
    // charset: 'RE',
    required: true,
    options: [],
    type: variablesTypes.LIST,
  },
  // sections: {
  //   type: variablesTypes.LIST,
  //   multiple: true,
  //   options: []
  //   // enable: [ variablesTypes.LIST ]
  // },
  charset: {
    // charset: 'E',
    type: variablesTypes.LIST,
    multiple: true,
    options: [
      { value: 'R', label: 'Кириллица' },
      { value: 'E', label: 'Латинница' },
      { value: 'D', label: 'Цифры' },
      { value: 'S', label: 'Символы' }
    ]
  },
  minLength: {
    charset: 'D',
    // enable: [ variablesTypes.STRING, variablesTypes.DATE ]
  },
  maxLength: {
    charset: 'D',
    // enable: [ variablesTypes.STRING, variablesTypes.DATE ]
  },
  length: {
    charset: 'D',
    // enable: [ variablesTypes.STRING, variablesTypes.DATE ]
  },
  values: {
    type: variablesTypes.LIST,
    multiple: true,
    options: [],
    creatable: true
    // enable: [ variablesTypes.LIST ]
  },
  regexp: {
    inputTooltip: '<div>^\\d{3}-\\d{3}$</div>'
    // enable: [ variablesTypes.STRING ]
  },
  mask: {
    inputTooltip: '<div><div>9: [0-9]</div><div>a: [A-Za-z]</div><div>*: [A-Za-zА-Яа-я0-9]</div></div>'
    // enable: [ variablesTypes.STRING ]
  },
  labelLineCount: { charset: 'D' },
  labelWidth: { charset: 'D' },
  inputWidth: { charset: 'D' },
  inputText: {
    inputWidth: 440,
    // charset: 'REDS'
  },
  inputTooltip: {
    inputWidth: 350,
    charset: 'REDS'
  },
  errorMessage: {
    inputWidth: 600,
    charset: 'REDS'
  },
  description: {
    inputWidth: 600,
    maxLength: 300,
    charset: 'REDS'
  },
  multiple: {
    type: variablesTypes.CHECKBOX
  },
  required: {
    type: variablesTypes.CHECKBOX
  },
  ownVariant: {
    type: variablesTypes.CHECKBOX
  }
};

export const strictattrsFoldersSchema = {
  id: {
    charset: 'D',
    required: true,
    inputWidth: 100
  },
  priority: {
    charset: 'D',
    required: true,
    inputWidth: 120
  },
  name: {
    // charset: 'RE',
    required: true,
    inputWidth: 400
  },
  attrs: {
    required: true,
    type: variablesTypes.LIST,
    multiple: true,
    options: [],
    inputWidth: 400
  },
};

export const strictattrsSectionsSchema = {
  id: {
    charset: 'D',
    required: true,
    inputWidth: 100
  },
  name: {
    // charset: 'RE',
    required: true,
    inputWidth: 400
  },
  scope: {
    charset: 'E',
    required: true,
    inputWidth: 200
  },
  folders: {
    required: true,
    type: variablesTypes.LIST,
    multiple: true,
    options: {},
    inputWidth: 400
  }
};

export const resourcesSchema = {
  id: {
    inputWidth: 300,
    charset: '',
    required: true
  },
  lang: {
    inputWidth: 100,
    charset: '',
    required: true,
    type: variablesTypes.LIST,
    options: [
      { label: 'ru', value: 'ru' },
      { label: 'en', value: 'en' },
    ]
  },
  value: {
    inputWidth: 410,
    charset: '',
    required: true,
    type: 'textarea',
    maxLength: 9999
  }
};

export const tariffsSchema = {
  id: {
    charset: '',
    required: true
  },
  level: {
    charset: 'D',
    required: true,
  },
  name: {
    charset: '',
    required: true
  },
  usersLimit: {
    charset: 'D',
    required: true,
  },
  canAddUsers: {
    // required: true,
    type: variablesTypes.CHECKBOX,
  },
  advantages: {
    required: true,
    type: variablesTypes.LIST,
    options: [],
    multiple: true,
    creatable: true
  }
};

export const tariffDurationSchema = {
  id: {
    charset: '',
    required: true
  },
  name: {
    charset: '',
    required: true
  },
  duration: {
    charset: 'D',
    required: true,
  },
  discount: {
    charset: 'D',
  },
  price: {
    charset: 'D',
    required: true,
  },
  basePrice: {
    charset: 'D',
    required: true,
  },
  userPrice: {
    charset: 'D',
    required: true,
  },
  canChoose: {
    // required: true,
    type: variablesTypes.CHECKBOX,
  },
  canRenewal: {
    // required: true,
    type: variablesTypes.CHECKBOX,
  }
};

export const demoRequestSchema = {
  timeslotId: {
    charset: '',
    required: true
  },
  date: {
    charset: '',
    required: true,
    type: variablesTypes.DATE,
    showTimeSelect: true
  },
  gmt: {
    charset: '',
    required: true
  },
  clientPhone: {
    charset: '',
    required: true
  },
  clientEmail: {
    charset: '',
    required: true,
    inputWidth: 440
  },
  companyName: {
    charset: '',
    required: true,
    inputWidth: 440
  },
  clientFullName: {
    charset: '',
    required: true,
    inputWidth: 800
  },
  clientComment: {
    charset: '',
    required: true,
    inputWidth: 800
  },
  chanel: {
    charset: '',
    required: true
  },
  managerId: {
    charset: '',
    required: true
  }
};

export const promocodeSchema = {
  id: {
    charset: '',
    required: true
  },
  type: {
    charset: '',
    required: true,
    options: [],
    type: variablesTypes.LIST,
  },
  description: {
    charset: '',
    required: true,
    inputWidth: 440
  },
  dtFrom: {
    charset: '',
    required: true,
    type: variablesTypes.DATE,
  },
  dtTo: {
    charset: '',
    required: true,
    type: variablesTypes.DATE,
  },
  discountPercent: {
    charset: '',
  },
  discountAmount: {
    charset: '',
  },
  patternId: {
    charset: '',
    options: [],
    type: variablesTypes.LIST,
  },
  trialProlongDays: {
    charset: '',
  },
  reusable: {
    type: variablesTypes.CHECKBOX,
    // required: true
  },
  usesLimit: {
    charset: 'D'
  }
};

export const templateCategorySchema = {
  name: {
    charset: '',
    required: true
  },
  parentid: {
    charset: '',
    options: [],
    type: variablesTypes.LIST,
    // required: true
  }
};

export const adminProfileSchema = {
  login: {
    charset: '',
    required: true
  },
  name: {
    charset: '',
    required: true
  },
  userid: {
    charset: '',
    required: true
  },
  role: {
    charset: '',
    options: adminRoles.reduce((acc, key) => [...acc, { label: key, value: key }], []),
    type: variablesTypes.LIST,
    required: true
  }
};

export const externalFillEntitySchema = {
  companyname: {
    charset: '',
    inputWidth: 1200,
    name: 'Полное наименование организации или ИП',
    required: true
  },
  address: {
    charset: '',
    inputWidth: 1200,
    name: 'Юридический адрес',
    required: true
  },
  inn: {
    charset: '',
    inputWidth: 220,
    required: true,
    errorMessage: 'Для ИП - 12 цифр, для Юридического лица - 10 цифр',
    inputTooltip: 'Для ИП - 12 цифр, для Юридического лица - 10 цифр',
    inputText: 'Введите 10 или 12 цифр',
    maxLength: 12,
    minLength: 10,
    type: 'string',
    name: 'ИНН Юр. лица / ИП',
    regexp: '^(\\d{10}|\\d{12})$' // nonstandard
  },
};
